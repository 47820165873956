jQuery("body").find("[data-search-toogle]").css("cursor","pointer");
jQuery("body").find("[data-search-close]").css("cursor", "pointer");

jQuery("body").find("[data-search-toogle]").click(function () {
    

    var headerHeight = jQuery("header").height();
    var headerPadding = jQuery("header").css("margin-top").replace("px", "");
    var popupHeight = parseInt(headerHeight) + parseInt(headerPadding);
    jQuery(".popup--search").height(popupHeight);
    jQuery(".popup--search").toggle();
    jQuery(".search__field").focus();
});


jQuery("body").find("[data-search-close]").click(function () {

    jQuery(".popup--search").hide();

});


jQuery(".search__result-item").click(function () {

    var href= jQuery(this).find("a").attr("href");

    window.location.href = href;

});
 

